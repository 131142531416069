.map-marker
{
    width: 8rem;
    position: absolute;
    transform:  translate(-50%, calc(-50% - 2rem));

}
.map-marker-point
{
    width: 1rem;
    position: absolute;
    transform:  translate(-50%, calc(-50% - 0.5rem));

}
.map-marker-point span {
    font-family: "nexabold";
    position: absolute;
    background: var(--map-marker-color);
    border-radius: 1rem;

    padding: 0.3rem 0.6rem;
    color:white;
    font-size:0.8rem;
    display:block;
    width: 12rem;
    text-align: center;
    top:50%;
    left:50%;
    transform: translate(-50%,-140%);
}
.map-marker-point .st0 {
    fill : var(--map-marker-color);
}
.map-marker-point.down span {
    transform: translate(-50%, 40%);
}

.distance-label {
    position: fixed;
    left: 2rem;
    bottom: 2rem;
    color : var(--map-text-color);
    font-size: 4rem;
    line-height: 0rem;
}
.distance-label span {
    font-size:1rem;
}
.duration-label {
    position: fixed;
    left: 2rem;
    bottom: 6rem;
    color : var(--map-text-color);
    font-size: 1.5rem;
    line-height: 0rem;
}
.map-container {
    width:100%;
    height: 100%;
}