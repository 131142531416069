
section.brochure
{
    overflow: hidden;
}
.flipbook-container
{
    position: absolute;
    padding: 2em;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
    overflow: hidden;

}

.flipbook-page img
{
    max-width: 100%;
    height: auto
}